
/*------------------------------------------------------------------
  [Tables]
*/

.table-shadow {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}

.table tbody tr td.cell-detail span {
  display: block;
}

.table tbody tr td.cell-detail .cell-detail-description {
  display: block;
  font-size: 11px;
  color: #999999;
}

.table.table-borderless tbody tr td {
  border-top-width: 0;
}

.card-table table {
  margin-bottom: 0;
}

.card-table tr th:first-child, .card-table tr td:first-child {
  padding-left: 20px;
}

.card-table tr th:last-child, .card-table tr td:last-child {
  padding-right: 20px;
}

.card-table thead tr th {
  padding-top: 15px;
  padding-bottom: 10px;
}