.dealmed-products-filter {
	position: relative;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.dealmed-products-filter__btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding: 0 0.5rem;
	width: 100%;
	min-width: 206px;
	min-height: 36px;
	color: $grey-dark;
	background-color: transparent;
	border: none;
	border-radius: 5px;
	text-align: left;
	font-weight: 600;

	&:hover,
	&:focus {
		color: $white;
		background-color: $red-dark;

		.dealmed-icon {
			fill: $white;
		}
	}
}

.dealmed-products-filter__icon {
	fill: $red-dark;
}

.dealmed-products-filter__content {
	display: none;
	padding: 0 0.5rem;

	.dealmed-products-filter--expanded & {
		display: block;
	}
}

.dealmed-products-filter__list {
	margin: 0;
}

.dealmed-products-filter__item {
	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
}

.dealmed-products-filter__link {
	color: $grey-dark;

	&:hover,
	&:focus {
		color: $red-dark;
	}
}

@media only screen and (min-width: 90em) {
	.dealmed-products-filter {
		&:not(:last-child) {
			margin-bottom: 0;
			margin-right: 2.5rem;
		}
	}

	.dealmed-products-filter__btn {
		margin-bottom: 0;
		padding: 0.5rem 0.5rem;
		border: 1px solid $grey-mid-2;
		border-radius: 0;

		&:hover,
		&:focus {
			color: $red-dark;
			background-color: transparent;
			border-color: $red-dark;

			.dealmed-icon {
				fill: $red-dark;
			}

			.dealmed-products-filter--expanded & {
				border-color: $grey-mid-2;
			}
		}
	}

	.dealmed-products-filter__content {
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		padding: 0.5rem 0.5rem;
		width: 100%;
		background-color: $white;
		border-bottom: 1px solid $grey-mid-2;
		border-left: 1px solid $grey-mid-2;
		border-right: 1px solid $grey-mid-2;
		z-index: 1;
	}
}
