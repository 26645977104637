@import '../../assets/CSS/globals';

/* Autocomplete */
.aa-SubmitIcon {
  fill:black;
}

.aa-Panel {
  position: fixed;
  top: 124px !important;
  z-index: 6001;
  margin-top: 0;
  font-family: 'IBM Plex Sans';
  border-radius: 12px;
  border:1px solid #F4F3F1;
  padding:12px 8px;

  @media (max-width: $screen-lg-min) {
    top: 120px !important;
  }
}
.aa-PanelLayout {
  display:flex;
  flex-direction: column;
  gap:8px;
  padding:0;
}
.aa-Label {
  margin-bottom: 0;
}

.aa-Form {
  height: 44px;
  border: 1px solid #E4E7E8;
  border-radius: 8px;
  background-color: #F1F3F3;
  font-family: 'IBM Plex Sans';
  border-radius: 6px;
  @media (min-width:1024px) and (max-width:1439px) {
    height: 40px;
  } 
}

.aa-Form:focus-within {
  border-color: rgba(135, 33, 46, 1);
  box-shadow: 0px 0px 0px white;
}

.aa-Item[aria-selected='true'] {
  background-color: rgba(135, 33, 46, 0.15);
}
.aa-Item {
  padding:0 8px;
  border-radius: 8px;
  min-height:40px;
  &:hover {
    background-color: #F4F3F1;
    & .aa-ItemContentTitle {
      color:rgba(135, 33, 46, 1);
    }
  }
  & .aa-ItemIcon {
    width:14px;
    height:14px;
    & svg {
      fill:#9B9191;
    }
  }  
}
.aa-Input {
  font-family: 'IBM Plex Sans';
  font-size:14px;
  &::placeholder {
    color:#171A1C;
  }
}



.aa-ItemActionButton svg {
  fill:#7E7C78;
  &:hover {
    fill:#b92a47;
  }
}

$start: 0;
$end: 9;

@for $i from $start through $end {
  #autocomplete-#{$i}-recentSearchesPlugin-list  {
    &::before {
      content:'Recent';
      font-size:12px;
      padding-left:10px;
      color:#9B9191;
    }
  }
  #autocomplete-#{$i}-querySuggestionsPlugin-list {
    &::before {
      content:'Recommended';
      font-size:12px;
      padding-left:10px;
      color:#9B9191;
    }
  }
}


/* InstantSearch */

/* .ais-Hits-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .ais-Hits-item {
    padding: 1rem !important;
  } */

/* .hit {
    align-items: center;
    display: grid;
    gap: 1rem;
  }
  
  .hit h1 {
    font-size: 1rem;
  }
  
  .hit p {
    font-size: 0.8rem;
    opacity: 0.8;
  }
  
  .hit-image {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
  }
  
  .hit-image img {
    max-height: 100%;
  } */

// .ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent
//   > div:first-of-type
//   .ais-HierarchicalMenu-label {
//   font-weight: bold;
// }

// .ais-HierarchicalMenu-item--selected:not(.ais-HierarchicalMenu-item--parent)
//   .ais-HierarchicalMenu-label {
//   font-weight: bold;
// }

// .ais-Pagination {
//   display: flex;
//   justify-content: center;
//   margin: 2rem 0;
// }