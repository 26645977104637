@import '../../assets/CSS/globals';
@import '../Content/base/base';
@import '../Content/base/mixins';
// @import "base/media-mixin";
@import '../Content/base/mixins';
@import '../Content/base/media-mixin';
@import '../Content/base/vars';
@import '../Content/base/btn';
@import '../Content/base/typography';

$clr-black: #20272b !default;
$clr-blackActive: #384349 !default;
$clr-grey: #7d7f8a !default;
$clr-lightGrey: #f1f3f7 !default;
$clr-white: #fff !default;
$clr-red: #b92a47 !default;
$clr-border: #dddee2 !default;
$clr-green: #75c25f !default;

  .quickorder {
    & p, span, h1, h2, h3, h4, h5, h6 {
      font-family: 'IBM Plex Sans';
    }
    &__products-box-buttons {
      display:flex;
      padding:0 12px;
      gap: 8px;
      align-items: center;
      @media screen and (max-width:767px) {
        padding:0 8px;
      }
      & .dealmed-product__add-to-cart {
        background-color:#B92A47;
        border-radius: 4px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height:40px;
        @media screen and (min-width:768px) and (max-width:1079px) {
          height:36px;
        }
        @media screen and (max-width:767px) {
          height:32px;
        }
        &:hover {
          background-color: $color-point-red-60;
          transition: 0.3s;
          .quickorder__products-box-buttons-addtocart-title, .quickorder__products-box-buttons-addtolist-select {
            color:white;
          }
        }
      }
      & .quickorder__products-box-buttons-addtolist {
        background-color: $color-point-red-90;
        border-radius: 4px;
        min-width: 40px;
        height:40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding:6px;
        cursor: pointer;
        @media screen and (min-width:768px) and (max-width:1079px) {
          min-width: 36px;
          height:36px;
          padding:5px;
        }
        @media screen and (max-width:767px) {
          min-width: 32px;
          height:32px;
          padding:4px;

        }
        & > svg {
          fill:#B92A47;
        }
        &:hover {
          background-color: $color-point-red-80;
          transition: 0.3s;
          & > svg {
            fill:white;
          }
        }
      }
    }
    
    &__sidebar {
      width: 100%;
      margin-right: 0;
      background: $clr-white;
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.07);
      margin-bottom: 30px;
      @include media(">=tablet") {
        margin-bottom: 0;
        // width: 26%;
        margin-right: 40px;
      }
  
      &-top {
        padding: 30px;
        @include d-flex-between;
        border-bottom: 1px solid $clr-border;
  
        &-text {
          color: $clr-blackActive;
          font-size: 16px;
          font-weight: bold;
          line-height: 2.25;
          letter-spacing: 0.54px;
        }
  
        &-button {
          cursor: pointer;
          background: transparent;
          border: 0;
          outline: 0;
          color: $clr-red;
          font-size: 16px;
          line-height: 2.25;
          letter-spacing: 0.54px;
        }
      }
  
      &-filter {
        &-title {
          font-size: 20px;
          letter-spacing: 0.68px;
          color: $clr-grey;
          padding: 30px 30px 0 30px;
        }
      }
  
      &-block {
        padding: 30px;
        border-bottom: 1px solid $clr-border;
        position: relative;
  
        &-indicator {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
          width: 11px;
          height: 11px;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          top: 40px;
          right: 30px;
          transform: rotate(-90deg);
  
          &.active {
            display: block;
            transform: rotate(0);
          }
        }
  
        &-checkboxLabel {
          font-size: 16px;
          line-height: 2.25;
          letter-spacing: 0.54px;
          text-align: left;
          color: $clr-blackActive;
          position: relative;
          top: 2px;
          margin-bottom: 0px;
        }
  
        // &-checkboxField {
  
        //   margin-right: 20px;
  
        //   &:before {
        //     transition-timing-function: cubic-bezier(.075, .820, .165, 1);
        //     border: 4px solid;
        //     border-radius: 0;
        //     background-color: white;
        //     border-color: transparent;
        //     box-sizing: border-box;
        //     color: #3acfb6;
        //     content: close-quote;
        //     display: inline-block;
        //     height: 20px;
        //     outline: 2px solid #dbdbdb;
        //     transition-duration: .5s;
        //     transition-property: background-color, border-color;
        //     width: 20px;
        //   }
  
        //   &:checked:before {
        //     background-color: $clr-red;
        //     border-color: white;
        //   }
        // }

        &-checkboxField {
          appearance: none;
          background-color: white;
          border: 2px solid #dbdbdb; // You can adjust this as required
          width: 20px;
          height: 20px;
          position: relative;
          cursor: pointer;
          margin-right: 16px;
          outline: none;
          
          &:before {
            content: '\2713';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none; // By default, the checkmark will be hidden
            color: $clr-red; // Checkmark color
            // color: white; // Checkmark color
            font-size: 20px; // Adjust the size of the checkmark as required
            line-height: .8; // This ensures the checkmark is centered vertically
          }
        
          &:checked:before {
            display: block;
            // background-color: $clr-red; // Adjust this if you want a background color when checked
            background-color: white; // Adjust this if you want a background color when checked
            border-color: white;
            // border-color: $clr-red;
          }
        }

        &-heading {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.61px;
          color: $clr-red;
          cursor: pointer;
  
          &.active {
  
            & + .listing__sidebar-block-list {
              display: block;
            }
  
          }
        }
  
        &-list {
          list-style: none;
          padding: 0;
          margin: 0;
          display: none;
        }
  
        &-item {
          display:flex;
          align-items:center;
          margin-bottom: 10px;
  
          &-link {
            font-size: 16px;
            font-weight: 500;
            line-height: 2.25;
            letter-spacing: 0.54px;
            color: $clr-blackActive;
            text-decoration: none;
          }
  
          &:nth-child(1) {
            margin-top: 15px;
          }
  
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
  
      }
  
    }
  
    &__main {
      width: 100%;
  
      @include media(">=tablet") {
        // width: 76%;
      }
    }
  
    &__wrapper {
      display: flex;
      align-items: flex-start;
      max-width: 1660px;
      margin: 0 auto;
      padding: 0 16px;
      margin-bottom: 40px;
      flex-direction: column;
      @include media(">=tablet") {
        flex-direction: row;
        padding: 0 20px;
        margin-bottom: 80px;
  
      }
    }
  
    &__products {
  
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap:4px;
      flex-wrap: wrap;
      
      @include media(">=phone-m") {
        justify-content: flex-start;
      }
  
      &-box {
        // margin-bottom: 20px;
        background-color: white;
        border-radius: 12px;
        overflow: hidden;
        max-width:360px;
        // min-height: 550px;
        border:1px solid #E6E6E6;
        &-is-modal {
          max-width: 255px;
        }
        &-wrapper {
          padding: 0;
          display:flex;
          flex-direction: column;
          // justify-content: space-between;
          &-previously {
            padding:8px 12px;
            display:flex;
            align-items: center;
            @media screen and (max-width:767px) {
              padding:4px 8px;
            }
          }
        }

        &-allocation {
          fill:#675f61;
              width:1.2rem;
              height:1.2rem;
              @media screen and (max-width:767px) {
                width:9px;
                height:9px;
              }
        }
  
        &-image-container {
          position:relative;
          display: flex;
          background-color: #FFFFFF;
          align-items: center;
          justify-content: center;
          height:  fit-content;
          width: 100%;
        }

        &-image {
          // display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin-bottom: 0px;
          // min-height: 255px;
        }
  
        &-manufacturer {
          font-size: 14px;
          color: #B92A47;
          margin-bottom: 4px;
          font-family: 'IBM Plex Sans';
          @media screen and (min-width:768px) and (max-width:1079px) {
            font-size:12px;
          }
          @media screen and (max-width:767px) {
            font-size:10px;
          }
        }
  
        &-title {
          font-size: 18px;
          color: #161A1D;
          margin: 0 0 6px 0;
          min-height:40px;
          @media screen and (min-width:768px) and (max-width:1079px) {
            font-size:14px;
            min-height:35px;
          }
          @media screen and (max-width:767px) {
            font-size:12px;
            min-height:30px;
          }
        }

        &-titles {
          padding:4px 12px 12px 12px;
          @media screen and (max-width:767px) {
            padding:8px 8px 0 8px;
          }
        }
  
        &-inputs {
          padding:0 12px;
          @media screen and (max-width:767px) {
            padding:0 8px;
          }

          &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            flex-direction: column;
            @include media(">=desktop-l") {
              flex-direction: row;
            }
  
            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
  
          &-number {
            display: flex;
            align-items: center;
            border: 1px solid $clr-border;
            max-width: 100%;
            min-height: 44px;
            background: $clr-white;
  
            @include media(">=desktop-l") {
              max-width: 110px;
            }
  
            &-btn {
              border: 0;
              background: transparent;
              font-size: 18px;
              color: $clr-grey;
              padding: 0 8px;
            }
  
            &-input {
              border: 0;
              width: 100%;
              text-align: center;
              font-size: 16px;
              letter-spacing: -0.16px;
              color: $clr-black;
              font-family: 'Campton', sans-serif;
            }
          }
  
          &-byBox,
          &-color {
  
            &-select {
              width: 100%;
              min-width: 186px;
              border: 1px solid $clr-border;
              min-height: 46px;
              height: 46px;
              border-radius: 0;
              text-align: center !important;
              display: flex;
              align-items: center;
              justify-content: center;
  
              &::after {
                border-bottom: 2px solid $clr-black;
                border-right: 2px solid $clr-black;
              }
  
              span.current {
                font-family: 'Campton', sans-serif;
                font-size: 16px;
                letter-spacing: -0.16px;
                color: $clr-black;
                font-weight: normal;
              }
  
              .list {
                width: 100%;
              }
  
            }
          }
  
          &-color {
            &-select {
              width: 100%;
              min-width: 100%;
            }
          }
  
          &-block {
            display:flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;
            flex: 1;
            @media screen and (min-width:768px) and (max-width:1079px) {
              gap: 6px;
            }
            @media screen and (max-width:767px) {
            }
            & > .dealmed-number-input, .quickorder__products-box-inputs-byBox {
              width:50%;
            }
            &-text {
              font-size: 10px;
              color: #7D7F8A;
              margin-top: 3px;
              margin-bottom: 3px;
              text-align: center;
              font-family: 'IBM Plex Sans';
              @media screen and (max-width:767px) {
                font-size:9px;
              }
              &.input-number {
                max-width: 100%;
  
                @include media(">=desktop-l") {
                  max-width: 130px;
                }
              }
            }

            &-matrixDetail {
              font-size: 11px;
              color: #7d7f8a;
              margin-bottom: 3px;
              font-family: 'IBM Plex Sans';
              @media screen and (max-width:767px) {
                font-size:10px;
              }
            }
          }
        }
        .item-card-out-of-stock, .item-card-in-stock {
          position: absolute;
          top:8px;
          right:8px;
          display:flex;
          gap:4px;
          background-color:#F7F7F7;
          border-radius: 4px;
          padding:4px 12px;
          align-items: center;
        }
        .item-card-in-stock {
          background-color: #F0FFEB;
        }
        &-stock {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 12px 8px 12px;
          gap:5px;
          @media screen and (max-width:767px) {
            padding:12px 8px;
          }

          &-block {
            display: flex;
            align-items: center;
            margin-left: 1rem;
            gap: 6px;
  
            img {
              margin-right: 5px;
            }
          }
  
          &-text {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.18px;
            color: $clr-green;
            position: relative;
            top: 1px;
          }

          &-price {
            font-size: 18px;
            color: black;
            font-weight: 600;
            font-family: 'IBM Plex Sans';
            @media screen and (min-width:768px) and (max-width:1079px) {
              font-size:16px;
            }
            @media screen and (max-width:767px) {
              font-size:12px;
            }
            &-before {
              font-size:14px;
              color:#7D7F8A;
              text-decoration: line-through;
              font-family: 'IBM Plex Sans';
              @media screen and (min-width:768px) and (max-width:1079px) {
                font-size:12px;
              }
              @media screen and (max-width:767px) {
                font-size:10px;
              }

            }
          }
        }
  
        &-buttons {
  
          &-addtocart {
  
            &-link {
              display: flex;
              align-items: center;
              justify-content: center;
              background: $clr-red;
              padding: 20px 0;
              width: 100%;
              color: $clr-white;
              text-decoration: none;
            }
  
            &-icon {
              margin-right: 10px;
            }
  
            &-title {
              font-size: 14px;
              font-weight: 600;
              line-height: normal;
              position: relative;
              color:white;
              @media screen and (min-width:768px) and (max-width:1079px) {
                font-size:12px;
              }
              @media screen and (max-width:767px) {
                font-size:10px;
              }
            }
          }
  
          &-addtolist {
  
            &-select {
              font-size: 12px;
              font-weight: 600;
              color:$color-point-red;
              text-align: center;
              
  
              &::after {
                border-bottom: 2px solid $clr-black;
                border-right: 2px solid $clr-black;
              }
  
              .list {
                background: $clr-white;
                width: 100%;
  
                &.selected {
                  background: #fbeeea;
                }
              }
            }
          }
        }
      }
    }
  
    &__featured {
  
      padding: 0 16px;
  
      &-title {
        margin-bottom: 60px;
      }
  
      &-heading {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: -1px;
        text-align: left;
        color: #20272b;
      }
  
      &-products {
        border-bottom: 2px solid $clr-border;
        padding-bottom: 45px;
        margin-bottom: 80px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
  
        @include media(">=phone-m") {
          justify-content: flex-start;
          gap:22px;
        }
  
        &-box {
          max-width: 100%;
          margin-bottom: 25px;
  
          @include media(">=phone-m") {
            max-width: 48%;
          }
  
          @include media(">=phone-l") {
            max-width: 30%;
          }
          @include media(">=tablet") {
            max-width: 16%;
          }
  
          &-top {
            border: 1px solid $clr-border;
            padding: 25px 25px 8px 25px;
            height: 365px;
          }
  
          &-image {
            text-align: center;
          }
  
          &-manufacturer {
            font-size: 12px;
            letter-spacing: -0.12px;
            color: $clr-grey;
            margin-bottom: 3px;
          }
  
          &-name {
            font-size: 16px;
            font-weight: normal;
            letter-spacing: -0.16px;
            text-align: left;
            color: $clr-black;
            margin-bottom: 9px;
          }
  
          &-price {
  
            &-text {
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.54px;
              color: $clr-red;
            }
          }
  
          &-buttons {
  
            &-addtocart {
  
              &-link {
                display: flex;
                align-items: center;
                justify-content: center;
                background: $clr-red;
                padding: 20px 0;
                width: 100%;
                color: $clr-white;
                text-decoration: none;
              }
  
              &-icon {
                margin-right: 10px;
              }
  
              &-title {
                font-size: 20px;
                font-weight: normal;
                letter-spacing: -0.23px;
                line-height: 100%;
                position: relative;
                top: 3px;
  
                @include media(">=bs-desktop-m") {
                  font-size: 23px;
                }
              }
            }
  
            &-addtolist {
  
              &-select {
                background: #fbeeea;
                width: 100%;
                text-align: center !important;
                border-radius: 0;
  
                &::after {
                  border-bottom: 2px solid $clr-black;
                  border-right: 2px solid $clr-black;
                }
  
                .list {
                  background: $clr-white;
                  width: 100%;
  
                  &.selected {
                    background: #fbeeea;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dealmed-quickorder {
    &__is, &__oos {
      &-text {
        color:#3BA116;
        font-family: 'IBM Plex Sans';
        font-size:14px;
        @media screen and (min-width:768px) and (max-width:1079px) {
          font-size:12px;
        }
        @media screen and (max-width:767px) {
          font-size:10px;
        }
      }
      &-icon {
        fill:#3BA116;
        width:12px;
        height:12px;
        @media screen and (max-width:767px) {
          width:10px;
          height:10px;
        }
      }
    }
    &__oos {
      &-text {
        color:#675f61;
      }
      &-icon {
        fill:#675f61 !important;
      }
    }
  }


  .dealmed-quickorder__availability-icon {
	width: 12px;
	height: 12px;
  // fill: #770D0D;
}

.dealmed-quickorder__availability-text {
	margin: 0;
	font-size: 14px;
  // color: #770D0D;
  font-family: 'IBM Plex Sans';
}

.dealmed-quickorder__add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 17px !important;
  width: 100%;
  min-height: 60px;
  color: #ffffff;
  background-color: #b92a47;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'IBM Plex Sans';
}

.quickorder-tab {
    background-color: #fbfcfe !important;
    font-family: "Campton", sans-serif;
    font-size: 42px;
    letter-spacing: -1.25px;
    font-weight: 500;
    margin: 0;
    // border-bottom: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    
}

.nav-tabs {
  border-bottom: none !important;
}

.nav-link {
  color: #495057 !important;
  padding:6.5px 16px;
}

.nav-link.active {
  color: #20272b !important;
  margin: 0;
  border-color: lightgrey  !important;
  border-bottom: 1px solid transparent !important;
  // box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1),
  //             -1px 0px 3px rgba(0, 0, 0, 0.1),
  //             1px 0px 3px rgba(0, 0, 0, 0.1);
}

// .cursor-hover{
//   color: #495057 !important;
// }

.quickorder-grid-container {
  padding-top:36px; 
  border-top: 1px solid #dee2e6;
}

.previously-ordered {
  font-size: 12px;
  color: #7D7F8A;
  font-family: 'IBM Plex Sans';
  @media screen and (max-width:767px) {
    font-size:9px;
  }
}


.home-section-title {
  color: #20272b;
  font-family: 'IBM Plex Sans';
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 32px;

&__white{
  color:white;
  margin-bottom: 24px;
}
}
.quickorder__products-box-buttons > .dealmed-product__unable {
  font-family: 'IBM Plex Sans';
  font-weight:600;
  font-size:14px;
  line-height: 125%;
  width:100%;
}

.quickOrder-price{
    color: $color-price;
    font-weight: bold;
    font-size: 26px;
    color:$clr-blackActive
}

.quickOrder-matrixDetails-container{
  display:flex;
  
}