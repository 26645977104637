.dealmed-paginator {
	display: flex;
	align-items: center;
}

.dealmed-paginator__page {
	margin: 0 0.625rem 0 0;
	color: $grey-dark;
	font-size: 0.75rem;
}

.dealmed-paginator__buttons {
	display: flex;
	align-items: center;
}

.dealmed-paginator__button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	color: $white;
	background-color: white;
	border: none;
	border-radius: 4px;


	&:first-child {
		margin-right: 0.25rem;
	}
	& svg {
		fill:#464041;
	}
	&:hover,
	&:focus {
		background-color: $red-dark;
		& svg {
			fill:white;
		}
	}

	&:disabled {
		background-color: #F4F3F1;
		& svg {
			fill:#DBCCCC;
		}
	}
}
