.dealmed-content-list__item {
	position: relative;
	padding: 30px 0;

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: -10px;
		left: 2.1875rem;
		width: 2px;
		height: 20px;
		background-color: $red-dark;
		transform: translateX(-50%);
	}

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;

		&::before {
			content: none;
		}
	}
}
