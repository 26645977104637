.loader-wrapper {
    position: fixed;
    z-index: 202;
    background: #fffffff3;
    width: 100%;
    height: 100%;
}

.loader {
    margin-top: 20%;
    @media (max-width: 700px) {
        margin-top: 60%;
    }
    display: flex;
    justify-content: center;
}