$white: #ffffff;
$black: #000000;

$grey-light: #dddee2;
$grey-light-2: #e4e6eb;
$grey-light-3: #f1f3f7;
$grey-mid: #cccccc;
$grey-mid-2: #7d7f8a;
$grey-mid-3: #6c757d;
$grey-dark: #384349;
$grey-dark-2: #20272b;

$red-light: #f9eeeb;
$red-dark: #b92a47;
$red-darker: #7a142c;

$green-light: #58c550;

$font-family-primary: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Archivo', sans-serif;
$font-archivo: 'Archivo', sans-serif;
$font-calibri: 'Calibri', sans-serif;
$font-campton: 'Campton', sans-serif;
$font-campton-light: 'Campton-light', sans-serif;
$font-campton-bold: 'Campton-bold', sans-serif;
$font-campton-book: 'Campton Book', sans-serif;
