@import "../../assets/CSS/globals";

.header-navbar {
  z-index: 203;
  position: relative;
  box-shadow: 0 11px 9px -5px hsla(0, 0%, 63.5%, 0.233);


  .nav-top-row {
    justify-content: space-between;
    margin-left: 12.5vw;
    margin-right: 12.5vw;

    // @media (min-width: 1500px) {
    //   .nav-phone-col {
    //     flex: 0 0 19%;
    //     max-width: 19%;
    //   }
    // }

    .nav-purchasing-audit {
      letter-spacing: -0.16px;
      font: normal normal medium 16px/22px Campton;
      text-align: center;
      background-color: #f9eeeb;
      color: #b92a47;
      padding: 10px;
    }
    .main-nav-dropdown {
      font: normal normal normal 17px/22px Calibri;
      position: relative;
      color: #7d7f8a;
      padding: 10px;
    }
    .nav-phone-icon {
      color: #b92a47;
    }
    .nav-phone {
      color: #20272b;
      padding: 10px;
      font-family: $font-campton;
      font-weight: 600;
      letter-spacing: 1px;
      &:hover {
        color: #7d7f8a;
        text-decoration: none;
      }
    }
    .create-account {
      color: $color-primary-danger;
      padding: 13px 20px 13px 20px;
      background-color: $color-primary-danger-light;
      font-family: $font-campton;
      font-weight: 600;
      &:hover {
        color: white;
        background-color: $color-primary-danger;
      }
    }
  }

  .nav-main-row {
    padding-left: 12.5vw;
    margin-right: 12.5vw;

    @media (max-width: $screen-xl-min) {
      padding-left: 0;
      margin-right: 0;
    }

    .main-nav-dropdown {
      font: normal normal normal 17px/22px Calibri;
      position: relative;
      color: #7d7f8a;

      &:hover {
        cursor: pointer;
        color: black;
        text-decoration: none;
      }
      .drop-down {
        box-shadow: 0px 11px 37px #00000012;
        width: 300%;
        visibility: hidden;
        opacity: 0;
        text-align: left;
        position: absolute;
        background-color: white;
        left: -100%;
        z-index: 55;
        //for position fixed - top: 115px;
        top: 100%;

        &.show {
          visibility: visible;
          opacity: 1;
        }
        .support-phone {
          padding-left: 15px;
          padding-top: 15px;
          color: #20272b;
          &:hover {
            text-decoration: none;
          }
        }
        a {
          color: #7d7f8a;
        }
        a:hover {
          text-decoration: none;
        }
        .support-icon {
          padding-left: 15px;
          padding-top: 15px;
          color: #384349;
          &:hover {
            text-decoration: none;
          }
        }
        .s-icon {
          color: #7d7f8a !important;
        }
        .s-hover {
          color: #20272b;
        }
        .s-hover:hover {
          color: #b92a47;
        }
      }
    }

    #header-drop-down-box {
      text-decoration: none;
      cursor: default;
    }
  }

  img {
    margin-right: 15px;
    // @media (max-width: $screen-xl-min) {
    //   bottom: 0;
    // }

    max-width: 240px;
    align-self: center;
    bottom: 4px;
    position: relative;
  }

  .nav-bottom-row {
    margin-left: 12.5vw;
    margin-right: 12.5vw;

    .dropDownLinks {
      text-decoration: none;
      color: black !important;
      font-weight: 500;
      width: 100%;
      display: block;
      &:hover {
        text-decoration: none;
        color: black;
      }
    }
    .drop-down {
      box-shadow: 0px 11px 37px #00000012;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      text-align: left;
      position: absolute;
      // right:0;
      background-color: white;
      padding-top: 20px;
      padding-bottom: 20px;
      z-index: 55;
      //for position fixed - top: 115px;
      top: 100%;

      &.show {
        visibility: visible;
        opacity: 1;
      }
      .drop-down-link {
        padding: 20px !important;
        color: black;
        text-decoration: none;
        &:hover {
          background-color: #f9eeeb;
          border-left: 2px solid #b92a47;
          text-decoration: none;
        }
      }
    }

    .nav-cart-wrapper {
      position: relative;
      margin-right: 5px;
    }
    .link {
      color: inherit;
      background-color: inherit;
      text-decoration: inherit;
    }
    .tab {
      position: relative;
      font-weight: 600;
      padding: 10px 0;
      text-align: center;
      color: black;
      &:hover {
        cursor: pointer;
        color: white;
        background-color: #b92a47;
        text-decoration: none;
      }
    }
    // .nav-item {
    //   @media (min-width: 992px) and(max-width:1100px) {
    //     font-size: 15px;
    //   }

    // @media (min-width: 1100px) and(max-width:1300px) {
    //   font-size: 16px;
    // }

    //   @media (min-width: 1300px) and(max-width:1450px) {
    //     font-size: 17px;
    //   }

    //   font-family: helvetica;
    //   font-size: 18px;
    //   color: $color-secondary;
    //   padding: 11px 6px;
    //   cursor: pointer;
    //   position: relative;
    //   text-align: center;

    //   &:after {
    //     position: absolute;
    //     -webkit-transition: 0.15s;
    //     transition: 0.19s;
    //     content: "";
    //     width: 0;
    //     left: 50%;
    //     bottom: 5px;
    //     height: 4px;
    //     background: $color-primary;

    //     @media (max-width: 1450px) {
    //       height: 3px;
    //     }
    //   }

    //   &:hover:after {
    //     width: 100%;
    //     left: 0;
    //   }

    // &.nav-current {
    //   color: $color-primary;

    //   &:after {
    //     width: 100%;
    //     left: 0;
    //     background: $color-primary;
    //   }
    // }
    // }
  }

  .nav-cart-number {
    background-color: $color-primary;
    color: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    position: relative;
    font-size: 11px;
    display: inline-flex;
    line-height: 16px;
    right: 9px;
    font-family: helvetica;
    justify-content: center;
  }

  // .nav-right-side {
  //   position: static;
  // }

  // .nav-phone-number {
  //   font-family: Helvetica;
  //   font-weight: bold;
  //   letter-spacing: 0.1px;
  //   font-size: 18px;

  //   @media (max-width: 1560px) {
  //     font-size: 17px;
  //   }

  //   a {
  //     color: $color-dealmed-grey;
  //   }
  // }

  .reorder-icon {
    height: 33px;
  }

  #reorderIcon {
    display: block;
  }

  .mdi-shopping-outline {
    font-size: 25px;
  }

  .nav-logo-mobile {
    height: 50px;
  }

  .nav-mobile {
    padding: 10px 0;
    align-items: center;
  }

  // .nav-phone {
  //   height: 25px;
  //   margin-right: 25px;
  // }

  .header-drop-down {
    min-width: 680px;
    visibility: hidden;
    opacity: 0;
    list-style-type: none;
    text-align: left;
    position: absolute;
    // left: 0;
    // right:0;
    text-transform: none;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 55;
    //for position fixed - top: 115px;
    top: 45px;
    left: 0;
    .col-6 {
      padding: 0;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .drop-down-item {
      color: black;
      text-decoration: none;
      &:hover {
        background-color: #f9eeeb;
        border-left: 2px solid #b92a47;
        text-decoration: none;
      }
    }

    &:before {
      content: "";
      height: 20px;
      width: 100%;
      position: absolute;
      top: 0px;
      background: white;
      z-index: 2;
      left: 0;
    }
  }
}
// end header-navbar
//   ///////////////////////////////
//   //  LOGIN BUTTON DROPDOWN  ////
.login-btn-secondary {
  color: $color-primary;
  font-weight: bold;
}

.nav-dropdown-list {
  min-width: 680px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 130px;
  background-color: white;
  z-index: 55;
  left: 0;
  padding: 2% 0 2% 14%;
  box-shadow: 0 2px 37px rgba(0, 0, 0, 0.26);
  left: 30%;
  //  transform: translateX(-50%);
  transition: opacity ease-in-out 0.14s;
}

.login-dropdown-header {
  width: 100%;
  display: block;
  color: $color-primary;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
  padding: 6px 0;

  &:hover {
    text-decoration: none;
  }
}

.login-dropdown-sub-item {
  padding: 6px 0;
  padding-left: 7%;
  color: gray;
  font-family: $font-archivo;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

// .login-dropdown-top-row {
//     justify-content: space-between;
//     border-bottom: 1px solid lightgray;
//     padding: 0 0 14px 0;
//     margin-bottom: 40px;
// }

.login-dropdown-top-row-item {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.1em;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $color-primary-hover-bright;
  }
}

.login-dropdown-sign-out {
  font-size: 1.1em;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $color-primary-hover-bright;
  }
}

.show-dropdown {
  visibility: visible;
  opacity: 1;
}

////////////////
// CART POPOVER
////////////////
.popover.nav-popover {
  .popover-body {
    max-height: 442px;
    overflow: scroll;
  }

  .nav-popover-bottom-section {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0px; // z-index:5;
    background-color: white;
    padding: 5px 10px 18px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid $color-background-gray-dark;

    a:hover {
      text-decoration: none;
    }
  }

  max-width: 390px !important;
  left: -70px !important; // border-radius:5px !important;

  .arrow {
    left: 225px !important;
  }

  .popover-header {
    padding: 0.9rem 1.4rem !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 1.38em !important;
  }

  .popover-inner {
    width: 340px;
    padding-bottom: 82px;
  }
}
.popover {
  max-width: 350px;
}
.nav-popover-totals-row {
  padding: 18px 0px;
  padding-top: 5px;
  font-size: 1.3em;

  & > * {
    text-align: center;
  }
}

.nav-popover-buttons-row > * {
  text-align: center;
  padding: initial 10px !important;
}

.nav-popover-product-total {
  color: $color-price;
}

.nav-popover {
  border: 1px #ececec solid !important;
  border-radius: 3px !important;
}

.nav-popover-card {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-background-gray-dark;
}

.nav-popover-img-wrap {
  position: relative;
  text-align: center; //height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.nav-popover-name {
  font-size: 1.1em;
  color: $color-primary;
  margin-bottom: 3px;
}

.nav-popover-price {
  color: $color-price;
}

.nav-popover-item-remove {
  color: #B12704;
  &:hover {
    color: #000000;
    cursor: pointer;
  };
  font-size: 12px;
}
.nav-popover-quantity {
  font-size: 1em;
}

.nav-cart-link {
  text-decoration: none;
  color: inherit;
  display: flex;

  &:hover {
    color: inherit;
  }
}

// .nav_cart-link:hover {
//   text-decoration: none;
//   color: inherit;
// }

// .cart-drag {
//   display: flex;
//   justify-content: flex-end;
// }

// //////////////////
// // CHECKOUT NAVBAR
// //////////////////
  .checkout-navbar {
  @media(min-width: 1600px) {
    margin-left: 9% !important;
    margin-right: 8% !important;
  }
  
//   background-color: white;
//   box-shadow: 0 11px 9px -5px rgba(162, 162, 162, 0.16);
//   justify-content: space-between;
//   font-size: 20px;
//   padding: 12px 30px;

  img {
    height: 52px;
  }

//   @media (max-width: $screen-xs-max) {
//     padding-left: 5px !important;

//     img {
//       height: 45px;
//     }
//   }
}
