.dealmed-products {
	.dealmed-container {
		max-width: 90rem;
	}
}

.dealmed-products__header {
	margin-bottom: 2.5rem;

	.dealmed-breadcrumbs {
		margin-bottom: 1.75rem;
	}
}

.dealmed-products__title {
	font-size: 2rem;
}

.dealmed-products__settings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 1.25rem;
}

.dealmed-products__options {
	display: flex;
	width: 100%;
	max-width: 400px;
}

.dealmed-products__sort-dropdown {
	flex: 1;
	margin-left: 0.25rem;
	min-width: 145px;
	color: $red-dark;
	background-color: transparent;
	border: 1px solid $red-dark;
	border-radius: 5px;
	text-align: center;
	font-size: 1rem;
	font-weight: 500;

	[data-reach-listbox-button] {
		display: flex;
		justify-content: center;
	}
}

.dealmed-products__item {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.dealmed-products__footer {
	display: flex;
	justify-content: flex-end;
}

@media only screen and (min-width: 36em) {
	.dealmed-products__title {
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 48em) {
	.dealmed-products__title {
		font-size: 3rem;
	}

	.dealmed-products__listing {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		margin: 0 -1.25rem;
	}

	.dealmed-products__item {
		margin: 0 1.25rem 2.5rem;
		flex-basis: calc(50% - 2.5rem);
		width: calc(50% - 2.5rem);

		&:last-child {
			margin-bottom: 2.5rem;
		}
	}
}

@media only screen and (min-width: 75em) {
	.dealmed-products__title {
		font-size: 3.75rem;
	}

	.dealmed-products__item {
		flex-basis: calc(33.33% - 2.5rem);
		width: calc(33.33% - 2.5rem);
	}
}
