.dealmed-breadcrumbs__list {
	display: flex;
	margin: 0;
}

.dealmed-breadcrumbs__item {
	display: flex;
	align-items: center;
}

.dealmed-breadcrumbs__link {
	color: $grey-mid-2;

	&:hover,
	&:focus {
		color: $red-dark;
	}
}

.dealmed-breadcrumbs__icon {
	fill: $grey-mid-2;
}

.dealmed-breadcrumbs__icon-wrapper {
	display: flex;
}
