.dealmed-products-toolbar {
	display: flex;
	margin-bottom: 2rem;
}

.dealmed-products-toolbar__filters {
	display: flex;
	flex: 1;
	margin-right: 3rem;
}

.dealmed-products-toolbar__paginator {
	display: flex;
}
