.home-btn {
  &--grey {
    padding: 27px 25px 27px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #7d7f8a;
    font-family: 'Campton Book';
    line-height: 11px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $clr-white;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;

    @include media('>=tablet') {
      padding: 27px 46px 27px;
      font-size: 22px;
    }
  }
  &:hover {
    color: $clr-white;
  }
  &--small {
    padding: 14px 24px 14px 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}
