$breakpoints: (
        'phone': 320px,
        'phone-m': 575px,
        'phone-l': 768px,
        'tablet': 991px,
        'desktop': 1280px,
        'bs-desktop-m': 1400px,
        'desktop-m': 1480px,
        'desktop-l': 1660px
) !default;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Campton', sans-serif;
  margin: 0;
}

.container {
  box-sizing: content-box;
  width: 100%;
  margin: 0 auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (min-width: 576px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}


.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: all 0.5s ease;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.fw-light {
  font-weight: 300;
}
.fw-reg {
  font-weight: 400;
}
.fw-med {
  font-weight: 500 !important;
}
.fw-sb {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-eb {
  font-weight: 800;
}
.fs-i {
  font-style: italic;
}

.mx-auto {
  margin: 0 auto;
}

.page-holder {
  overflow: hidden;
}

.z-index-999999 {
  z-index: 999999;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.mobile-only {
  display: none;
}

.mb-10 {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}
