@import '../../assets/CSS/globals';

.breadcrumbs {
    text-transform: capitalize;
    font-family: var(--unnamed-font-style-normal) var(--unnamed-font-weight-medium) var(--unnamed-font-family-campton),
    sans-serif;;
    color: #7D7F8A !important;
}

.current-crumb {
    color: $color-text-black
}

.crumb-divider {
    position: relative;
    top: 1px;
}