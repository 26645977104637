.dealmed-spinner {
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 10px;
		border: 4px solid;
		border-color: hsla(0, 100%, 50%, 0.15) $red-dark hsla(0, 100%, 50%, 0.15) hsla(0, 100%, 50%, 0.15);
		border-radius: 50%;
		transform: translate(-50%, -50%);
		animation: spin 1500ms linear infinite;
	}
}

@keyframes spin {
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
