@mixin d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin d-inline-flex-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin pos-abs-0 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@mixin pos-abs-t-50 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

@mixin backgrounds-default {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
