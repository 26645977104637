.dealmed-content-distribution {
	padding: 2.5rem 0;
	background-color: #F4F3F1;

	.dealmed-container {
		max-width: 90rem;
	}
}

.dealmed-content-distribution__icons {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2.5rem;
}

.dealmed-content-distribution__icon {
	width: 8rem;
	height: 8rem;
	fill: $red-dark;

	&--arrow {
		width: 6rem;
		height: 8rem;
		transform: rotate(90deg);
	}
}

.dealmed-content-distribution__item {
	margin-bottom: 1rem;
	background-color: $white;
	border-radius: 24px;
	box-shadow: 0px 24px 24px 0px rgba(115, 82, 82, 0.05);
	&:last-child {
		margin-bottom: 0;
	}
}

.dealmed-content-distribution__card {
	border-radius: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 2rem 4rem;
	min-height: 150px;
	background-color: $white;
	text-align: center;
	height:100%;
}

.dealmed-content-distribution__card-title {
	font-family: 'IBM Plex Sans';
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}

.dealmed-content-distribution__card-text {
    font-weight: 400;
    font-size: 14px;
    font-family: 'IBM Plex Sans';

    @media(max-width: $screen-xs-max) {
    }
}

@media only screen and (min-width: 48em) {
	.dealmed-content-distribution {
		padding: 4rem 0;
	}

	.dealmed-content-distribution__icon {
		width: 10rem;
		height: 10rem;
		fill: $red-dark;

		&--arrow {
			width: 8rem;
			height: 10rem;
		}
	}

	.dealmed-content-distribution__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -0.5rem;
	}

	.dealmed-content-distribution__item {
		flex-basis: calc(50% - 1rem);
		width: calc(50% - 1rem);
		margin: 0.5rem;

		&:last-child {
			margin: 0.5rem;
		}
	}
}

@media only screen and (min-width: 62em) {
	.dealmed-content-distribution {
		padding: 6rem 0;
	}

	.dealmed-content-distribution__icons {
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 2.5rem;
	}

	.dealmed-content-distribution__icon {
		width: 8rem;
		height: 8rem;

		&--arrow {
			width: 13rem;
			transform: none;
		}
	}

	.dealmed-content-distribution__item {
		flex-basis: calc(33.33% - 1rem);
		width: calc(33.33% - 1rem);
	}

	.dealmed-content-distribution__card {
		min-height: 195px;
	}

}

@media only screen and (min-width: 75em) {
	.dealmed-content-distribution {
		padding: 7rem 0;
	}

	.dealmed-content-distribution__icon {
		width: 10rem;
		height: 10rem;

		&--arrow {
			width: 15rem;
		}
	}

	.dealmed-content-distribution__list {
		margin: 0 -1.5rem;
	}

	.dealmed-content-distribution__item {
		flex-basis: calc(33.33% - 3rem);
		width: calc(33.33% - 3rem);
		margin: 1.5rem;

		&:last-child {
			margin: 1.5rem;
		}
	}
}
