@import '../../../assets/CSS/global_variables';
/*------------------------------------------------------------------
  EKG Loader SVG
*/

.ekg-loader-wrapper {
    position: fixed;
    z-index: 202;
    background: #ffffffbd;
    width: 100%;
    height: 100%; // display: flex;
    // justify-content: center;
    // padding-top: 200px;
    // svg {
    //     height: 300px;
    // }
}

.ekg-loader {
    fill: none;
    stroke: $color-primary;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: miter;
    opacity: 0;
    stroke-dashoffset: 1000;
    stroke-dasharray: 1000;
    animation: ekg-loader 3s linear forwards infinite;
    -webkit-animation: ekg-loader 3s linear forwards infinite;
    -moz-animation: ekg-loader 3s linear forwards infinite;
}

@keyframes ekg-loader {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        stroke-dashoffset: 2000;
    }

    99% {
        opacity: 0;
        stroke-dashoffset: 3000;
    }

    100% {
        stroke-dashoffset: 100;
    }
}

@-webkit-keyframes ekg-loader {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        stroke-dashoffset: 2000;
    }

    99% {
        opacity: 0;
        stroke-dashoffset: 3000;
    }

    100% {
        stroke-dashoffset: 1000;
    }
}

@-moz-keyframes ekg-loader {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        stroke-dashoffset: 2000;
    }

    99% {
        opacity: 0;
        stroke-dashoffset: 3000;
    }

    100% {
        stroke-dashoffset: 1000;
    }
}

///HEART LOADER
.heart-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.heart {
    position: absolute;
    z-index: 202;
    font-size: 70px;
    color: #903434;
    margin-top: 45%;

    @media (max-width: 600px) {
        margin-top: 70%;
    }

    animation: beat .7s infinite alternate;
}

@keyframes beat {
    to {
        transform: scale(1.4);
    }
}