.mobile-dealmed-main-navigation {
  font-family: 'IBM Plex Sans' !important;
}

.mobile-dealmed-main-navigation__toggle-btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $grey-dark;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.mobile-dealmed-main-navigation__toggle-btn-icon {
  width: 24px;
  height: 24px;
}
.mobile-dealmed-main-navigation__overlay{
  z-index: 9998;
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background-color: rgba(0, 0, 0, 0.4);
}
// mobile
.mobile-dealmed-main-navigation__wrapper {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 9999;

  &--expanded {
    display: flex;
    width: 100%;
    max-width: 380px;
    height: 100%;
    flex-direction: column;
   
    & .mobile-dealmed-main-navigation__btn {
      background: transparent;
      border:none;
      padding:10px 24px ;
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
    }
    & .mobile-dealmed-main-navigation__link{
      background: transparent;
      border:none;
      padding:12px 24px ;
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
    }
    & .mobile-dealmed-main-navigation__btn-text, .mobile-dealmed-main-navigation__link {
      font-size:14px;
      text-decoration: underline;
      font-weight: 400;
      color:black;
    }
    & .mobile-dealmed-main-navigation__btn-text {
      text-align:left;
    }
    & .mobile-dealmed-main-navigation__account-icon {
      display:none;
    }
    & .mobile-dealmed-main-navigation__account-name {
      margin:0;
      color:black;
    }
    & .mobile-dealmed-main-navigation__account-top {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 300px;
      color: $grey-dark;
    }
    & .mobile-dealmed-main-navigation__account-bottom {
      padding: 12px 0;
      font-size:18px;
      & .mobile-dealmed-main-navigation__account-link {
        color:black;
        border-right:1px solid #7E7C78;
      }
      & .mobile-dealmed-main-navigation__account-logout {
        background-color: transparent;
        border:none;
      }
    }
    & .mobile-dealmed-main-navigation__account-related {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      & label {
        font-size:12px;
      }
    }
    .mobile-dealmed-main-navigation__menu,
    .mobile-dealmed-main-navigation__submenu {
      left:0;
      padding:0;
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.mobile-dealmed-main-navigation__header {
  display: flex;
  justify-content: space-between;
  padding: 16px 10px;
  border-bottom: 1px solid $grey-mid;
}

.mobile-dealmed-main-navigation__logo-icon {
  width: 2.75rem;
  height: 2.75rem;
}

.mobile-dealmed-main-navigation__account {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobile-dealmed-main-navigation__account-link {
  color: $red-dark;

  &:not(:last-child) {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid $red-dark;
  }

  &:hover,
  &:focus {
    color: $grey-dark;
  }
}

.mobile-dealmed-main-navigation__close-btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $grey-dark;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.mobile-dealmed-main-navigation__body {
  position: relative;
  flex: 1;
}

.mobile-dealmed-main-navigation__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  color:black;

  &:hover {
    color:$red-dark;    
  }
}

//mobile submenus

@media (max-width: 992px) { /* Adjust the max-width value based on your mobile breakpoint */
  .mobile-dealmed-main-navigation__list {
    display:flex;
    flex-direction: column;
    height:100%;
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 100vh;
  }


.mobile-dealmed-main-navigation__back-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  width: 100%;
  color: #000;
  border: none;
  border-radius: 0;
  gap:2px;
  background-color: transparent;
  &-icon{
    width: 10px;
    height: 12px;
    margin-right: 2px;
  }
}

.mobile-dealmed-main-navigation__back-btn-icon-wrapper {
  margin-right: 0.625rem;
}
.mobile-dealmed-main-navigation__menu,
.mobile-dealmed-main-navigation__submenu {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
  top:0;
  background-color: white;
  transition: transform 300ms ease-in-out;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index:9999;

  &--expanded {
    transform: translateX(0);
  }

  &--visible {
    visibility: visible;
    opacity: 1;
  }}
  // @media (max-width:768px) {
  //   width:100%;
  //   height:100%;
  //   overflow-y: auto;
  // }
  // @media (min-width: 768px) { // Apply this style only if the screen width is greater than the mobile breakpoint
  //       &--overflow {
  //     overflow-y: auto;
  //   }
  // }
}

// .mobile-dealmed-main-navigation__submenu {
//   left: 12px;
//   border-radius: 12px;
//   background-color: white;
//   padding:8px;
// }

// @media only screen and (min-width: 62em) {
//   .mobile-dealmed-main-navigation__toggle-btn,
//   .mobile-dealmed-main-navigation__header {
//     display: none;
//   }

//   .mobile-dealmed-main-navigation__wrapper {
//     display: block;
//     position: static;
//   }

//   // .mobile-dealmed-main-navigation__body {

//   //   & > .mobile-dealmed-main-navigation__list {
//   //     display: flex;
//   //     justify-content: center;
//   //     align-items: center;
//   //     height: 100%;
//   //     flex-direction: row;
//   //     gap:20px;
//   //     margin-bottom:0;
      
//   //     @media (max-width: 1280px) {
//   //       gap:8px;
//   //     }

//   //     & > .mobile-dealmed-main-navigation__item {
//   //       position:relative;

//   //       & > .mobile-dealmed-main-navigation__btn, > .mobile-dealmed-main-navigation__link {
//   //         display: flex;
//   //         justify-content: space-between;
//   //         align-items: center;
//   //         padding: 10px 20px;
//   //         border-radius: 8px;
//   //         color: black;
//   //         font-size: 18px;
//   //         white-space: nowrap;
//   //         border:none;
//   //         font-weight:500;
//   //         background-color:transparent;
//   //         gap: 4px;
          
//   //         @media (max-width: 1280px) {
//   //           padding: 10px 10px;
//   //           font-size: 16px;
//   //         }
    
//   //         &:hover {
//   //           background-color: #F4F3F1;
//   //         }
        
//   //         & svg {
//   //           width:14px;
//   //           height:14px;
//   //         }
//   //       }

//   //       & > .mobile-dealmed-main-navigation__link {
//   //         justify-content: center;
//   //       } 
//   //     }
//   //   }
//   // }

//   .mobile-dealmed-main-navigation__btn-icon {
//     transform: rotate(90deg);
//   }

//   // first level submenu on desktop
//   .mobile-dealmed-main-navigation__menu {
//     background: rgba(255, 255, 255, 1);
//     border: 1px solid #F4F3F1;
//   }
//   .mobile-dealmed-main-navigation__menu,
//   .mobile-dealmed-main-navigation__submenu {
//     top: 64px;
//     left: 0;
//     bottom: unset;
//     transform: translateX(0);
//     overflow: visible;
//     border-radius: 12px;
//     padding:8px;

//     & .mobile-dealmed-main-navigation__list {
//       display:flex;
//       flex-direction: column;
//     }
   

//     & .mobile-dealmed-main-navigation__btn,
//     .mobile-dealmed-main-navigation__link {
//       display:flex;
//       background-color: transparent;
//       border: none;
//       border-radius: 8px;
//       padding:8px 12px;
//       justify-content: space-between;
//       width:100%;
      
//       font-size:14px;
//       color:black;
//       white-space: nowrap;

//       & .mobile-dealmed-main-navigation__btn-text {
//         white-space: nowrap;
//       }
//       &:hover {
//         background-color: #F4F3F1;
//         color: $red-dark;
//         transition:none;
//       }
    
//       & svg {
//         width:12px;
//         height:12px;
//       }
//     }
//     .mobile-dealmed-main-navigation__link {
//       &:hover {
//         background-color: #F4F3F1;
//         color: $red-dark;
//       }
//     }

//     .mobile-dealmed-main-navigation__btn-icon {
//       transform: none;
//     }
//   }

//   // second level submenu on desktop

//   .mobile-dealmed-main-navigation__submenu {
//     top: 0;
//     width: 100%;
//     left: 8px;
//     background: rgba(244, 243, 241, 1);
//     display:none;
//     &--expanded {
//       transform: translateX(100%);
//       display:block;
//     }
//     & .mobile-dealmed-main-navigation__link {
//       &:hover {
//         background-color: white;
//         color: $red-dark;
//       }
//     }
//   }

//   .mobile-dealmed-main-navigation__back-btn {
//     display: none;
//   }
// }
.main-navigation-title-mobile__menu,
.main-navigation-title-mobile__submenu {
  position: relative; 
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
  top: 0;
  background-color: white;
  transition: transform 300ms ease-in-out, visibility 0s, opacity 0s;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 9999;
}
.main-navigation-title-mobile__menu--expanded,
.main-navigation-title-mobile__submenu--expanded {
  transform: translateX(0);
  overflow-y: auto;
}

.main-navigation-title-mobile__menu--visible,
.main-navigation-title-mobile__submenu--visible {
  visibility: visible;
  opacity: 1;
}

.main-navigation-title-mobile__submenu {
  left: 0; /* Ajustar la posición */
  border-radius: 12px;
  background-color: white;
  padding: 8px;
}

.main-navigation-title-mobile__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 12px;
  background: transparent;
  border: none;
  width: 100%;
  overflow-y: scroll;

}

.main-navigation-title-mobile__btn-text {
  font-size: 16px;
  font-weight: 600;
  color: black;
  text-align: left;
  &--submenu-title{
    padding: 12px;
    font-size: 16px;
  font-weight: 600;
  color: black;
  text-align: left;
  }
}

.main-navigation-title-mobile__icon-wrapper {
  display: flex;
  align-items: center;
}

.main-navigation-title-mobile__btn-icon {
  width: 24px;
  height: 24px;
}
