.home-small-banner-container{
	display: flex;
	flex-direction: row;
	justify-content: space-around;

}

.dealmed-content-banner {
	padding-top: 2.5rem;

	.dealmed-container {
		padding: 0;
		max-width: 90rem;
	}
}
.dealmed-small-content-banner {
	padding: 2.5rem 1rem;
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap:66px;
	max-height: 194px;
}

.dealmed-content-banner__content {
	margin-bottom: 1.25rem;
	padding: 0 16px;
}

.dealmed-small-content-banner__title {
	flex:1;
	margin-bottom: 1.25rem;
	max-width: 20ch;
	color: $red-dark;
	line-height: 2.625rem;
	font-size: 2.25rem;
	font-weight: 700;
}

.dealmed-small-content-banner__text {
	flex:1;
	margin: 0;
	max-width: 45ch;
	color: $grey-dark-2;
	line-height: 1.375rem;
	font-size: 1rem;
	font-weight: 700;
}
.dealmed-content-banner__title {
	margin-bottom: 1.25rem;
	max-width: 20ch;
	color: $red-dark;
	line-height: 2.625rem;
	font-size: 2.25rem;
	font-weight: 700;
}

.dealmed-content-banner__text {
	margin: 0;
	max-width: 45ch;
	color: $grey-dark-2;
	line-height: 1.375rem;
	font-size: 1rem;
	font-weight: 700;
}

.dealmed-content-banner__image {
	display: block;
	width: 100%;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
}

.dealmed-small-content-banner__image {
	display: block;
	width: 100%;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
}

@media only screen and (min-width: 48em) {
	.dealmed-content-banner {
		padding-top: 0;

		.dealmed-container {
			display: flex;
			align-items: center;
			padding: 0 16px;
		}
	}

	.dealmed-content-banner__content {
		flex: 1;
		margin-bottom: 0;
		margin-right: 0.5rem;
		padding: 0;
	}
	// .dealmed-small-content-banner__content {
	// 	display:flex;
	// 	flex-direction: row;
	// 	justify-content: space-between;
	// 	align-items: center;
	// }

	.dealmed-small-content-banner__image-wrapper {
		flex: 1;
		margin-left: 0.5rem;
	}
	
	.dealmed-content-banner__image-wrapper {
		margin-left: 0.5rem;
	}

	.dealmed-content-banner__image {
		object-fit: contain;
		max-height: 500px;
	}
	.dealmed-small-content-banner__image {
		object-fit: contain;
		max-height: 194px;
	}
}

@media only screen and (min-width: 62em) {
	.dealmed-content-banner__title {
		line-height: 3.25rem;
		font-size: 2.75rem;
	}

	.dealmed-content-banner__text {
		line-height: 1.75rem;
		font-size: 1.125rem;
	}
}

@media only screen and (min-width: 75em) {
	.dealmed-content-banner__title {
		line-height: 3.75rem;
		font-size: 3.5rem;
	}

	.dealmed-content-banner__text {
		line-height: 2.5rem;
		font-size: 1.375rem;
	}
}

.dealmed-small-content-banner__text-wrapper {
	display: flex;
	flex-direction: column;
}
