@import './utils/variables';

@import './globals';

.site-main {
  // display: flex;
  // flex-direction: column;
  // margin: auto 100px;
  // @media (max-width: 991px) {
  //   margin: auto;
  // }
  // min-height: 100vh;
}

.myaccount-page {
  display: flex;
  flex-direction: column;
  margin: auto 100px;
  @media (max-width: 1090px) {
    margin: auto;
  }
}
@import './components/dealmed-radio-button';
@import './components/dealmed-checkbox';
@import './components/dealmed-number-input';
@import './components/dealmed-listbox';
@import './components/dealmed-spinner';
@import './components/dealmed-icon';
@import './components/dealmed-popover';
@import './components/dealmed-breadcrumbs';
@import './components/dealmed-dialog';

@import './components/dealmed-header';
@import './components/dealmed-main-navigation';
@import './components/dealmed-main-navigation-mobile';
@import './components/dealmed-products';
@import './components/dealmed-products-toolbar';
@import './components/dealmed-product';
@import './components/dealmed-paginator';
@import './components/dealmed-products-filter';
@import './components/dealmed-products-dialog';
@import './components/dealmed-content';
@import './components/dealmed-content-banner';
@import './components/dealmed-content-section';
@import './components/dealmed-content-heading';
@import './components/dealmed-content-list';
@import './components/dealmed-content-item';
@import './components/dealmed-content-distribution';
@import './components/dealmed-breadcrumbs';
@import './components/dealmed-checkbox';
@import './components/dealmed-dialog';
@import './components/dealmed-listbox';
@import './components/dealmed-number-input';
@import './components/dealmed-paginator';
@import './components/dealmed-product';
@import './components/dealmed-products-dialog';
@import './components/dealmed-products-filter';
@import './components/dealmed-products-toolbar';
@import './components/dealmed-products';