@import '../../assets/CSS/globals';

.item-list-header {
    display: flex;
    align-items: center;

    .mdi-format-list-checkbox {
        margin-left: 10px;
        position: relative;
        top: 2px;
    }

    .mdi-chevron-left-circle-outline:before {
        font-size: 31px;
    }
}

.mdi-loading:before {
    margin-right: 10px;
}

.item-list-grid-row {
    &:hover {
        background-color: lighten($color-background-gray, 5%);
    }

    border-top: 1px solid $color-background-gray;
    font-family: $font-archivo;
    padding: 15px 0px;

    .item-list-grid-name {
        color: $color-primary;
        margin-bottom: 5px;
        font-weight: bold;
        cursor: pointer;
    }
}

.item-list-notes {
    border: none;
    width: 100%;
    margin-left: 4px;
    background-color: #e7e7e7;
    border-bottom: $color-primary 2px solid;
}

.item-list-fade {
    opacity: .38;
}

.item-list-grid-note-button {
    min-width: 99px;

    button:focus {
        box-shadow: none;
    }
}

//////////////////
// Item List Detail Grid DESKTOP
//////////////////
.item-list-detail-row {
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 15px;
    border-top: 1px solid #ededed;
    font-family: $font-archivo;
}

.item-list-single-price {
    color: $color-price;
    font-weight: bold;
    margin-top: 4px;
}

.item-list-single-name {
    color: $color-primary;
    font-weight: bold;
}

.item-list-detail-img {
    max-width: 100px;
    max-height: 100px;
}

.item-list-grid-btn-col {
    @media (min-width: 1350px) {
        padding-left: 5.5% !important;
        padding-right: 5.5% !important;
    }
}

//////////////////
// Item List Detail Grid MOBILE
//////////////////
.ild-grid-mobile {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-background-gray;
    padding-top: 10px;
    padding-bottom: 13px;
    border-bottom: 1px solid $color-background-gray-dark;
    border-bottom: 1px solid $color-background-gray-dark;
}

.ild-displayname-mobile {
    font-size: 13px;

    @media (min-width: 500px) {
        font-size: 16px;
    }
}

.ild-image-mobile {
    max-height: 130px;
    max-width: 100%;
}

.add-item-to-list {
    
    
    
    label {
        color: $color-primary-bright;
        font-size: 26px;
        margin-bottom: 0;
        height: 55px;
        width: 58px;
        padding-top:.25em;
    }

    .add-item-to-list-btn {
        border-radius: 5px !important;
        border: 1px solid $color-primary-danger-light;
        padding: 0 !important;
        margin-left: 65px;
        
    }

    .add-item-to-list-btn:hover {
        background-color: white !important;
        color: $color-primary !important;
        border: 1px solid $color-primary-danger;
    }

    >.dropdown-menu {
        width: 100%;
        border-radius: 0%;
        margin-top: 1px;
        background-color: rgb(253, 253, 253);
    }
}

.ild-pretty {
    font-size: 20px !important;

    .state label {

        &:before,
        &:after {
            border: 2px solid darken($color-background-gray-dark, 1%) !important;
        }
    }
}

.ild-checkbox {
    color: $color-primary !important;
}

.ild-pretty-slide {
    height: 15px !important;
}

.item-list-detail-select-all-row {
    padding: 12px 5px;
}